<template>
  <v-container grid-list-md fluid>
    <v-system-bar :color="sysbarColor" v-if="sysbarMessage != ''"> {{ sysbarMessage }} </v-system-bar>
    <v-layout align-center justify-space-around wrap>
      <v-flex md12>
        <v-toolbar color="primary" dark dense>
          <v-toolbar-title>Agents</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-flex>

      <v-flex md12>
        <v-card v-if="!(typeof getAgentById(agentid) === 'undefined')" elevation="1">
          <v-card-title>
            <v-autocomplete
              v-model="agentid"
              :items="allAgentIds"
              rounded
              hint="Type to search"
            >
              <template v-slot:prepend>
                <v-icon color="primary" @click.prevent="openAgentsPage()">arrow_back</v-icon>
              </template>
            </v-autocomplete>
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-switch
                  v-model="enablegrpc"
                  prepend-icon="settings_ethernet"
                  dense
                  @change="setGRPC()"
                  v-on="on"
                ></v-switch>
              </template>
              <span v-if="enablegrpc">Disable gRPC</span>
              <span v-else>Enable gRPC</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn small text :color="pinnedColor" @click.prevent="pinOrUnpin()" v-on="on">
                  <v-icon small dark left>push_pin</v-icon>
                  <span v-if="pinned">Unpin</span><span style="color:#1976D2" v-else>Pin</span>
                </v-btn>
              </template>
              <span v-if="pinned">Unpin</span>
              <span v-else>Pin</span>
            </v-tooltip>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn small text color="primary" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-layout row wrap>
                <v-flex md12>
                  <v-card>
                    <v-card-actions>
                      <v-list dense>
                        <v-list-item v-if="hasAaaAccess()">
                          <v-btn small text color="red" :loading="snack" :disabled="myAgent.status != 'Offline'" @click.prevent="confirmDelete = true">
                            <v-icon dark left>delete</v-icon>
                            Delete
                          </v-btn>
                        </v-list-item>
                        <v-list-item>
                          <v-btn small text color="primary" @click.prevent="exportAgent()">
                            <v-icon dark left>get_app</v-icon>
                            Export
                          </v-btn>
                        </v-list-item>
                      </v-list>
                    </v-card-actions>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-menu>
          </v-card-title>

          <v-divider></v-divider>

          <v-layout wrap>
            <v-flex md6>
              <v-simple-table dense>
                <tbody>
                  <tr>
                    <td>Tags</td>
                    <td colspan="2">{{ prettyTags(myAgent.tagsList) }}</td>
                  </tr>
                  <tr>
                    <td>Labels</td>
                    <td colspan="2">{{ prettyLabels(myAgent.labelsMap) }}</td>
                  </tr>
                  <tr>
                    <td>Alarms</td>
                    <td colspan="2" class="error--text">{{ prettyAlarms(myAlarms) }}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>
                      <v-icon small v-if='myAgent.status == "Registered"' color="success">check_circle_outline</v-icon>
                      <v-icon small v-else-if='myAgent.status == "Offline"' color="error">error_outline</v-icon>
                      <v-icon small v-else-if='myAgent.status == "RegisteredRedundant"' color="success">check_circle_outline</v-icon>
                      <v-icon small v-else-if='myAgent.status == "RegisteredDegraded"' color="warning">warning</v-icon>
                      <v-icon small v-else-if='myAgent.status == "RegisteredConflict"' color="warning">warning</v-icon>
                      {{ myAgent.status }}
                    </td>
                    <td>
                      <v-btn small text color="primary" :disabled='myAgent.status == "Offline"' @click.prevent="confirmRestart = true" v-if="hasNewTaskFullAccess()">
                        <v-icon small dark left>refresh</v-icon> Restart
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td>Last Seen</td>
                    <td>{{ dateToString(myAgent.lastseen) }}</td>
                    <td><AgentHistory :_agentid="agentid" /></td>
                  </tr>
                  <tr>
                    <td>Version</td>
                    <td>{{ myAgent.version }}</td>
                    <td>
                      <v-btn small text color="primary" :disabled='!upgradeAvailable()' @click.prevent="confirmUpgrade = true" v-if="hasNewTaskFullAccess()">
                        <v-icon small dark left>update</v-icon> Upgrade
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-flex>

            <v-flex md6>
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th class="text-left"> Package </th>
                    <th class="text-left"> Version </th>
                    <th class="text-left"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Service </th>
                    <th class="text-left"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Status </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(app, index) in myWatchers" :key="index">
                    <td>{{ app[1].name }}</td>
                    <td>{{ app[1].version }}</td>
                    <td>
                      <ul>
                        <li v-for="(daemon, index) in app[1].daemonsList" :key="index" style="padding:3px;">
                          {{ daemon.name }}
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul>
                        <li v-for="(daemon, index) in app[1].daemonsList" :key="index">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn icon small v-on="on" :disabled='myAgent.status == "Offline"' @click.prevent="toggleDaemon(daemon.name, daemon.status)">
                                <v-icon v-if="daemon.status == daemonStatus().ON" color="success" >toggle_on</v-icon>
                                <v-icon v-else-if="daemon.status == daemonStatus().OFF" color="info" >toggle_off</v-icon>
                                <v-icon v-else color="error">toggle_off</v-icon>
                              </v-btn>
                            </template>
                            <span v-if="daemon.status == daemonStatus().ON">Stop {{ daemon.name }}</span>
                            <span v-else-if="daemon.status == daemonStatus().OFF">Start {{ daemon.name }}</span>
                          </v-tooltip>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>

      <RequestDetails
        v-model="showRequest"
        :_request="request"
        :_aggregatedList="aggregatedList"
      />

      <v-flex md12 v-if="myAgent">
        <v-tabs slot="extension" v-model="tabs">
          <v-tab :key="0">Instances</v-tab>
          <v-tab :key="1">Tasks</v-tab>
          <v-tab :key="2">Alarms</v-tab>
          <v-tab :key="3">SSHPF</v-tab>
        </v-tabs>

        <v-tabs-items style="width:100%" v-model="tabs">

          <v-tab-item :key="0">
            <div>
              <v-tabs slot="extension" v-model="tabsInstances">
                <v-tab v-for="(instance, index) in Object.values(myInstances)" :key="index">
                  {{ instance.clientid }}
                </v-tab>
              </v-tabs>

              <v-tabs-items style="width:100%" v-model="tabsInstances">
                <v-tab-item v-for="(instance, index) in Object.values(myInstances)" :key="index">
                  <v-card>
                    <v-simple-table dense>
                      <tbody>
                        <tr>
                          <td>ClientID</td>
                          <td>{{ instance.clientid }}</td>
                          <td>
                             <v-btn
                              small
                              text
                              color="primary"
                              @click.prevent="clientid = instance.clientid; confirmCertificate = true"
                              v-if="agentid.includes('unconfigured') && instance.status != 'Offline'"
                            >
                              <v-icon small dark left>security</v-icon> Create certificate
                            </v-btn>
                          </td>
                        </tr>
                        <tr>
                          <td>Tags</td>
                          <td colspan="2">{{ prettyTags(instance.info.tagsList) }}</td>
                        </tr>
                        <tr>
                          <td>Labels</td>
                          <td>{{ prettyLabels(instance.info.labelsMap) }}</td>
                          <td colspan="2"></td>
                        </tr>
                        <tr>
                          <td>Alarms</td>
                          <td colspan="2" class="error--text">{{ prettyAlarms(myAlarms, instance.clientid) }}</td>
                        </tr>
                        <tr>
                          <td>Status</td>
                          <td>
                            <v-icon small v-if='instance.status == "Registered"' color="success">check_circle_outline</v-icon>
                            <v-icon small v-else-if='instance.status == "RegisteredRedundant"' color="success">check_circle_outline</v-icon>
                            <v-icon small v-else-if='instance.status == "Offline"' color="error">error_outline</v-icon>
                            <v-icon small v-else-if='instance.status == "RegisteredDegraded"' color="warning">warning</v-icon>
                            <v-icon small v-else-if='instance.status == "RegisteredConflict"' color="warning">warning</v-icon>
                            {{ instance.status }}
                          </td>
                          <td>
                            <v-btn small text color="primary" @click.prevent="openShell(instance.clientid)" :disabled="instance.status == 'Offline' || !hasNewTaskFullAccess()">
                              <v-icon small dark left>computer</v-icon> Shell
                            </v-btn>
                            <v-btn small text color="primary" @click.prevent="callOpenWebLocal(instance.clientid)" :disabled="instance.status == 'Offline'">
                              <v-icon small dark left>web</v-icon>
                              <div v-if="inTags(instance.info.tagsList, 'scada')"> Scada </div>
                              <div v-else-if="inTags(instance.info.tagsList, 'pac')"> TEL-UI </div>
                              <div v-else> Web </div>
                            </v-btn>
                            <v-btn small text color="primary" @click.prevent="callOpenWebRemote(instance.clientid)" :disabled="instance.status == 'Offline' || !hasNewTaskFullAccess()">
                              <v-icon small dark left>web</v-icon>
                              <div> Proxy </div>
                            </v-btn>
                            <v-btn small text color="primary" @click.prevent="callOpenSshPfDialog(instance.clientid)" :disabled="instance.status == 'Offline' || !hasNewTaskFullAccess()">
                              <v-icon small dark left>radar</v-icon>
                              <div> SSHPF </div>
                            </v-btn>
                          </td>
                        </tr>
                        <tr>
                          <td>Version</td>
                          <td colspan="2">{{ instance.version }}</td>
                        </tr>
                        <tr>
                          <td>Register Time</td>
                          <td colspan="2">{{ dateToString(instance.registerat) }}</td>
                        </tr>
                        <tr>
                          <td>Offline Time</td>
                          <td>{{ dateToString(instance.offlineat) }}</td>
                          <td>
                             <v-btn small text color="error"
                              @click.prevent="clientid = instance.clientid; confirmDeregister = true"
                              v-if="instance.status != 'Offline' && hasAaaAccess()"
                            >
                              <v-icon small dark left>remove_circle</v-icon> Deregister
                            </v-btn>
                          </td>
                        </tr>
                        <tr>
                          <td>Hostname</td>
                          <td colspan="2">{{ instance.info.hostname }}</td>
                        </tr>
                        <tr>
                          <td>OS</td>
                          <td colspan="2">{{ instance.info.os }}</td>
                        </tr>
                        <tr>
                          <td>Arch</td>
                          <td colspan="2">{{ instance.info.arch }}</td>
                        </tr>
                        <tr>
                          <td>CPU</td>
                          <td colspan="2">{{ instance.info.cpu }}</td>
                        </tr>
                        <tr>
                          <td>Memory</td>
                          <td colspan="2">{{ instance.info.memory }}</td>
                        </tr>
                        <tr v-for="eth in instance.info.ethernetList" :key="eth.name">
                          <td v-if="eth.name == instance.info.ethernetList[0].name">Ethernet</td>
                          <td v-else></td>
                          <td>{{ eth.name }} ({{ eth.hwaddr }})</td>
                          <td>
                              <template v-for="ip in [...eth.ipaddrList].sort()">
                                {{ ip }}
                              </template>
                          </td>
                        </tr>
                        <tr>
                          <td>Release</td>
                          <td>{{ instance.info.release.name }} {{ instance.info.release.version }}</td>
                          <td>ID: {{ instance.info.release.id }}, Version ID: {{ instance.info.release.versionid }}</td>
                        </tr>
                        <tr v-if="inTags(instance.info.tagsList, 'pac')">
                          <td>Identity</td>
                          <td>ControllerID</td>
                          <td>{{ instance.info.identity.controllerid.be }}</td>
                        </tr>
                        <tr v-if="inTags(instance.info.tagsList, 'pac')">
                          <td></td>
                          <td>ControllerIDX</td>
                          <td>{{ instance.info.identity.controlleridx }} </td>
                        </tr>
                        <tr v-if="inTags(instance.info.tagsList, 'pac')">
                          <td></td>
                          <td>ControllerModel</td>
                          <td>{{ instance.info.identity.controllermodel }} </td>
                        </tr>
                        <tr v-if="inTags(instance.info.tagsList, 'pac')">
                          <td></td>
                          <td>CPROCAgentName</td>
                          <td>{{ instance.info.identity.cprocagentname }} </td>
                        </tr>
                        <tr v-if="inTags(instance.info.tagsList, 'pac')">
                          <td></td>
                          <td>HostID</td>
                          <td>{{ instance.info.identity.hostid }} </td>
                        </tr>
                        <tr v-if="inTags(instance.info.tagsList, 'pac')">
                          <td></td>
                          <td>SiteID</td>
                          <td>{{ instance.info.identity.siteid }} </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-tab-item> 
              </v-tabs-items>
            </div>
          </v-tab-item>

          <v-tab-item :key="1">
            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
                <NewTask :agentid="agentid" ref="NewTask" />
                <v-btn small text color="primary" @click.prevent="refreshTasks()" :disabled="loadingAggregated">
                  <v-icon small dark left>refresh</v-icon>
                  Refresh
                </v-btn>
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn small text color="primary" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-layout row wrap>
                    <v-flex md12>
                      <v-card>
                        <v-card-actions>
                          <v-list dense>
                            <v-list-item>
                              <v-btn small text color="primary" @click.prevent="exportTasksCSV()">
                                <v-icon dark left>get_app</v-icon>
                                Export (CSV)
                              </v-btn>
                            </v-list-item>
                            <v-list-item>
                              <v-btn small text color="primary" @click.prevent="exportTasks()">
                                <v-icon dark left>get_app</v-icon>
                                Export (JSON)
                              </v-btn>
                            </v-list-item>
                          </v-list>
                        </v-card-actions>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-menu>
              </v-card-title>
            </v-card>

            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-card-title>

              <v-data-table
                :headers="aggregatedHeaders"
                :footer-props="{
                  'items-per-page-options': itemsPerPage
                }"
                :items="aggregatedList"
                :loading="loadingAggregated"
                :search="search"
                :sort-by="'submitted'"
                :sort-desc="true"
                :page.sync="pageAggregated"
                @page-count="pageCountAgregated = $event"
                item-key="skey"
                class="elevation-1"
                dense
                @update:options="updatePagination"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.skey">
                      <td><v-icon small>assignment</v-icon></td>
                      <td @click.stop="selectRequest(item.requestid)" class="underline" :style="{cursor:'pointer'}">{{ smallId(item.requestid) }}</td>
                      <!-- <td>{{ item.agentid }}</td> -->
                      <td>{{ smallId(item.clientid) }}</td>
                      <td>{{ item.owner }}</td>
                      <td>{{ dateToString(item.submitted) }}</td>
                      <td>{{ dateToString(item.completed) }}</td>
                      <td>{{ smallStr(item.command, 15) }}</td>
                      <td v-if='(item.jobid || item.filetransferid) && item.status == jobStatus().STARTING'><v-icon small color="info">hourglass_full</v-icon>STARTING</td>
                      <td v-else-if='(item.jobid || item.filetransferid) && item.status == jobStatus().STARTED'><v-icon small color="info">hourglass_full</v-icon>STARTED</td>
                      <td v-else-if='(item.jobid || item.filetransferid) && item.status == jobStatus().DONE'><v-icon small color="success">done</v-icon>DONE</td>
                      <td v-else-if='(item.jobid || item.filetransferid) && item.status == jobStatus().FAILED'><v-icon small color="error">error</v-icon>FAILED</td>
                      <td v-else-if='(item.jobid || item.filetransferid) && item.status == jobStatus().TIMEOUT'><v-icon small color="error">hourglass_empty</v-icon>TIMEOUT</td>
                      <td v-else-if='(item.jobid || item.filetransferid) && item.status == jobStatus().NOTFOUND'><v-icon small color="error">error</v-icon>NOTFOUND</td>
                      <td v-else-if='(item.jobid || item.filetransferid) && item.status == jobStatus().NOREPLY'><v-icon small color="warning">warning</v-icon>NOREPLY</td>
                      <td v-else-if='item.taskid && item.status == taskStatus().PENDING'><v-icon small color="info">hourglass_full</v-icon>PENDING</td>
                      <td v-else-if='item.taskid && item.status == taskStatus().PREPARING'><v-icon small color="info">hourglass_full</v-icon>PREPARING</td>
                      <td v-else-if='item.taskid && item.status == taskStatus().RUNNING'><v-icon small color="info">hourglass_full</v-icon>RUNNING</td>
                      <td v-else-if='item.taskid && item.status == taskStatus().COMPLETED'><v-icon small color="success">done</v-icon>COMPLETED</td>
                      <td v-else-if='item.taskid && item.status == taskStatus().CANCELLED'><v-icon small color="warning">warning</v-icon>CANCELLED</td>
                      <td v-else-if='item.taskid && item.status == taskStatus().EXPIRED'><v-icon small color="error">hourglass_empty</v-icon>EXPIRED</td>
                      <td v-else-if='item.requestid && item.status == taskStatus().PENDING'><v-icon small color="info">hourglass_full</v-icon>PENDING</td>
                      <td v-else-if='item.requestid && item.status == taskStatus().PREPARING'><v-icon small color="info">hourglass_full</v-icon>PREPARING</td>
                      <td v-else-if='item.requestid && item.status == taskStatus().RUNNING'><v-icon small color="info">hourglass_full</v-icon>RUNNING</td>
                      <td v-else-if='item.requestid && item.status == taskStatus().COMPLETED'><v-icon small color="success">done</v-icon>COMPLETED</td>
                      <td v-else-if='item.requestid && item.status == taskStatus().CANCELLED'><v-icon small color="warning">warning</v-icon>CANCELLED</td>
                      <td v-else-if='item.requestid && item.status == taskStatus().EXPIRED'><v-icon small color="error">hourglass_empty</v-icon>EXPIRED</td>
                      <td v-else><v-icon small color="error">error</v-icon>UNKNOWN</td>
                      <td>{{ item.exitstatus }}</td>
                      <td>
                        <v-btn color="primary" x-small @click.prevent="displayCombinedOutput(item)" :style="{cursor:'pointer'}" v-if="item.combinedoutput">Show</v-btn>
                        <v-btn color="error" x-small @click.prevent="displayErrorOutput(item)" :style="{cursor:'pointer'}" v-if="item.error">Show</v-btn>
                        <v-btn color="info" x-small @click.prevent="displayFileTransfer(item)" v-else-if="item.fileobjectid && item.fileobjectid != ''">Show</v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
                <template v-slot:footer>
                  <div align="center" v-if="pageAggregated == pageCountAgregated || pageCountAgregated == 0">
                    <v-btn small text color="primary" @click.prevent="getEverythingMore()" :disabled="bce(startTasks)" v-if="!loadingAggregated">
                      Load More
                    </v-btn>
                    <v-btn small text color="primary" :disabled="true" v-else>
                      Loading...
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="2">
            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-btn small text color="primary" @click.prevent="refreshAlarms()">
                  <v-icon small dark left>refresh</v-icon>
                  Refresh
                </v-btn>
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn small text color="primary" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-layout row wrap>
                    <v-flex md12>
                      <v-card>
                        <v-card-actions>
                          <v-list dense>
                            <v-list-item>
                              <v-btn small text color="primary" @click.prevent="exportAlarms()">
                                <v-icon dark left>get_app</v-icon>
                                Export
                              </v-btn>
                            </v-list-item>
                          </v-list>
                        </v-card-actions>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-menu>
              </v-card-title>
            </v-card>

            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-card-title>

              <v-data-table
                :headers="alarmHeaders"
                :footer-props="{
                  'items-per-page-options': itemsPerPage
                }"
                :items="Object.values(myAlarms)"
                :loading="isLoading.alarms"
                :search="search"
                :sort-by="'date'"
                :sort-desc="true"
                :page.sync="pageAlarms"
                @page-count="pageCountAlarms = $event"
                item-key="alarmid"
                class="elevation-1"
                dense
                @update:options="updatePagination"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.alarmid" @click.prevent="showAlarm(item)" :style="{cursor:'pointer'}">
                      <td><v-icon small>alarm</v-icon></td>
                      <td>{{ smallId(item.alarmid) }}</td>
                      <td>{{ item.name }}</td>
                      <td v-if="item.status=='ON'">
                        <v-icon small color='error'>alarm_on{{ item.status }}</v-icon>
                      </td>
                      <td v-else>
                        <v-icon small color='info'>alarm_off{{ item.status }}</v-icon>
                      </td>
                      <td>{{ smallId(item.clientid) }}</td>
                      <td>{{ dateToString(item.date) }}</td>
                      <td>{{ dateToString(item.enddate) }}</td>
                      <td>{{ item.value }}</td>
                    </tr>
                  </tbody>
                </template>
                <template v-slot:footer>
                  <div align="center" v-if="pageAlarms == pageCountAlarms || pageCountAlarms == 0">
                    <v-btn small text color="primary" @click.prevent="getAlarmsMore()" :disabled="bce(startAlarms)" v-if="!isLoading.alarms">
                      Load More
                    </v-btn>
                    <v-btn small text color="primary" :disabled="true" v-else>
                      Loading...
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="3">
            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-btn small text color="primary" @click.prevent="callOpenSshPfDialog()" :disabled="!hasNewTaskFullAccess()">
                  <v-icon small dark left>radar</v-icon>
                  SSHPF
                </v-btn>
                <v-btn small text color="primary" @click.prevent="refreshTasks()" :disabled="loadingAggregated">
                  <v-icon small dark left>refresh</v-icon>
                  Refresh
                </v-btn>
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn small text color="primary" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-layout row wrap>
                    <v-flex md12>
                      <v-card>
                        <!-- <v-card-actions>
                          <v-list dense>
                            <v-list-item>
                              <v-btn small text color="primary" @click.prevent="exportTasksCSV()">
                                <v-icon dark left>get_app</v-icon>
                                Export (CSV)
                              </v-btn>
                            </v-list-item>
                            <v-list-item>
                              <v-btn small text color="primary" @click.prevent="exportTasks()">
                                <v-icon dark left>get_app</v-icon>
                                Export (JSON)
                              </v-btn>
                            </v-list-item>
                          </v-list>
                        </v-card-actions> -->
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-menu>
              </v-card-title>
            </v-card>

            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-card-title>

              <v-data-table
                :headers="sshpfHeaders"
                :footer-props="{
                  'items-per-page-options': itemsPerPage
                }"
                :items="sshpf"
                :loading="loadingAggregated"
                :search="search"
                :sort-by="'submitted'"
                :sort-desc="true"
                :page.sync="pageAggregated"
                @page-count="pageCountAgregated = $event"
                item-key="skey"
                class="elevation-1"
                dense
                @update:options="updatePagination"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.skey">
                      <td><v-icon small>radar</v-icon></td>
                      <td @click.stop="selectRequest(item.requestid)" class="underline" :style="{cursor:'pointer'}">{{ smallId(item.requestid) }}</td>
                      <td>{{ smallId(item.clientid) }}</td>
                      <td>{{ item.owner }}</td>
                      <td>{{ dateToString(item.submitted) }}</td>
                      <td>{{ dateToString(item.expires) }}</td>
                      <td v-if='item.active'><v-icon small color="success">done</v-icon>Active</td>
                      <td v-else><v-icon small color="error">error_outline</v-icon>Inactive</td>
                      <td>{{ item.allocatedport }}</td>
                    </tr>
                  </tbody>
                </template>
                <template v-slot:footer>
                  <div align="center" v-if="pageAggregated == pageCountAgregated || pageCountAgregated == 0">
                    <v-btn small text color="primary" @click.prevent="getEverythingMore()" :disabled="bce(startTasks)" v-if="!loadingAggregated">
                      Load More
                    </v-btn>
                    <v-btn small text color="primary" :disabled="true" v-else>
                      Loading...
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>

        </v-tabs-items>
      </v-flex>
    </v-layout>

    <v-dialog
      v-model="webproxydialog"
      width="550"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-layout wrap md12>
            <v-flex md12>
              Enter the remote IP or Host. It must be resolvable to a private network on the other end.
              <div class="red--text text--lighten-1">
                Warning! This will close any currently opened sessions for all users started from this agent.
              </div>
            </v-flex>
            <v-flex md12>
              <v-text-field
              v-model="remote"
              label="Location"
              required
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="webproxydialog = false">Cancel</v-btn>
          <v-btn color="primary" text @click.prevent="callOpenWeb(); webproxydialog = false;">Open</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="webopendialog"
      width="550"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-layout wrap md12>
            <v-flex md12>
              Click on the link below to open the remote web page. 
              <div v-if="webpage">
                <!-- <a :href="webpage" target="_blank" rel="noreferrer noopener external" @click="webopendialog = false">
                  {{ webpage.split("_")[0] }}
                </a> -->
                <form method="post" :action="webpage" class="inline" target="_blank">
                  <button type="submit" name="sessionkey" :value="sessionkey" class="link-button" @click="webopendialog = false">
                    {{ webpage.split("/_")[0] }}
                  </button>
                </form>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="webopendialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="sshpfdialog"
      width="550"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex md12>
                Enter the remote forward location in &lt;Host|IP&gt;:&lt;Port&gt; format,
                the duration in seconds and a comma seperated list of allowed IPs
                (your current IP will be added automatically).
              </v-flex>
              <v-flex md10>
                <v-text-field
                v-model="sshpfhostport"
                label="Location"
                required
                ></v-text-field>
              </v-flex>
              <v-flex md2>
                <v-text-field
                v-model="sshpftimeout"
                label="Duration (s)"
                required
                ></v-text-field>
              </v-flex>
              <v-flex md12>
                <v-text-field
                v-model="sshpfallowedips"
                label="Allowed IPs"
                required
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="sshpfdialog = false">Cancel</v-btn>
          <v-btn color="primary" text @click.prevent="callOpenSshPf(); sshpfdialog = false;">Open</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmCertificate"
      width="550"
    >
      <v-card>
        <v-card-title>Enter Common Name for Agent</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="cn"
            label="Common Name"
            :rules="cnRules"
            single-line
            clearable
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="confirmCertificate = false">Cancel</v-btn>
          <v-btn color="primary" text @click.prevent="confirmCertificate = false; createCertificate();">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmRestart"
      width="500"
    >
      <v-card>
        <v-card-title>Run operation?</v-card-title>
        <v-card-text>
          <div>
          "coolagent" will be restarted on {{ agentid }}.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="confirmRestart = false">Cancel</v-btn>
          <v-btn color="primary" text @click.prevent="confirmRestart = false; coolagentRestart();">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmUpgrade"
      width="500"
    >
      <v-card>
        <v-card-title>Run operation?</v-card-title>
        <v-card-text>
          <div>
          "coolagent" will be upgraded on {{ agentid }} to v{{ upgradeVersion }}.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="confirmUpgrade = false">Cancel</v-btn>
          <v-btn color="primary" text @click.prevent="confirmUpgrade = false; coolagentUpgrade();">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirm"
      width="500"
    >
      <v-card>
        <v-card-title>Run operation?</v-card-title>
        <v-card-text>
          <div v-if="daemonstatus == daemonStatus().ON">
          "{{ daemonname }}" will be stopped on {{ agentid }}.
          </div>
          <div v-else>
            "{{ daemonname }}" will be started on {{ agentid }}.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="confirm = false">Cancel</v-btn>
          <v-btn color="primary" text @click.prevent="applyToggleDeamonStatus(); confirm = false">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmDeregister"
      width="550"
    >
      <v-card>
        <v-card-title>Deregister Instance?</v-card-title>
        <v-card-text>
            "{{ clientid }}" will be deregistered for {{ agentid }}.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="confirmDeregister = false">Cancel</v-btn>
          <v-btn color="primary" text @click.prevent="callDeregisterInstance(); confirmDeregister = false">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmDelete"
      width="500"
    >
      <v-card>
        <v-card-title>Delete Agent?</v-card-title>
        <v-card-text>
          <div>
            "{{ agentid }}" will be deleted. You can't undo this action.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="confirmDelete = false">Cancel</v-btn>
           <v-btn color="primary" text @click.prevent="callDeleteAgent(); confirmDelete = false">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="displayAlarm"
      hide-overlay
      width="800"
    >
      <v-card v-if="myAgent && selectedAlarm">
        <v-card-title>{{ myAgent.agentid }}</v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <tbody>
              <tr>
                <td>AlarmID</td>
                <td>{{ selectedAlarm.alarmid }}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{{ selectedAlarm.name }}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td v-if="selectedAlarm.status=='ON'">
                  <v-icon small color='error'>alarm_on{{ selectedAlarm.status }}</v-icon>
                </td>
                <td v-if="selectedAlarm.status=='OFF'">
                  <v-icon small color='info'>alarm_off{{ selectedAlarm.status }}</v-icon>
                </td>
              </tr>
              <tr>
                <td>ClientID</td>
                <td>{{ selectedAlarm.clientid }}</td>
              </tr>
              <tr>
                <td>Date</td>
                <td>{{ dateToString(selectedAlarm.date) }}</td>
              </tr>
              <tr>
                <td>End Date</td>
                <td>{{ dateToString(selectedAlarm.enddate) }}</td>
              </tr>
              <tr>
                <td>Value</td>
                <td>{{ selectedAlarm.value }}</td>
              </tr>
              <tr>
                <td colspan="2">Details</td>
              </tr>
              <tr>
                <td colspan="2"><pre>{{ decodeCombinedOutput(selectedAlarm.details) }}</pre></td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="displayAlarm = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showCombinedOutput"
      hide-overlay
      width="800"
    >
      <v-card>
        <v-card-title>Output on {{ hostid }}</v-card-title>
        <v-card-text>
          <pre>{{ combinedOutput }}</pre>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showCombinedOutput = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snack"
      :color="snackColor"
      :timeout="snackTimeout"
      :top="true"
    >
      {{ snackMessage }}
      <v-btn
        text
        @click="snack = false"
      >
        <div class="v-menu__content--active" style="display:none; z-index:1000;"></div>
        Close
      </v-btn>
    </v-snackbar>
  
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import auth from "../auth/authService";
import config from '../../config/config.json';
import libvue from '../lib/vue.js'
import AgentHistory from '../components/AgentHistory.vue'
import RequestDetails from '../components/RequestDetails.vue'
import NewTask from '../components/NewTask.vue'

export default {
  mixins: [libvue],
  data() {
    return {
      tabs: 0,
      tabsInstances: 0,
      agentid: this.$route.params.AgentID,
      filter: this.$route.params.filter,
      routechange: false,
      clientid: null,
      hostid: null,
      cn: "",
      taskid: null,
      requestid: null,
      submitted: null,
      search: null,
      aggregatedHeaders: [
        { text: '', sortable: false, value: '' },
        { text: 'RequestID', sortable: true, value: 'requestid' },
        // { text: 'AgentID', sortable: true, value: 'agentid' },
        { text: 'ClientID', sortable: true, value: 'clientid' },
        { text: 'Owner', sortable: true, value: 'owner' },
        { text: 'Submitted', sortable: true, value: 'submitted' },
        { text: 'Completed', sortable: true, value: 'completed' },
        { text: 'Recipe/Command', sortable: true, value: 'command' },
        { text: 'Status', sortable: true, value: 'status' },
        { text: 'Exit Status', sortable: true, value: 'exitstatus' },
        { text: 'Output', sortable: false, value: 'combinedoutput' }
      ],
      alarmHeaders: [
        { text: '', sortable: false, value: '' },
        { text: 'AlarmID', sortable: true, value: 'alarmid' },
        { text: 'Name', sortable: true, value: 'name' },
        { text: 'Status', sortable: true, value: 'status' },
        // { text: 'AgentID', sortable: true, value: 'agentid' },
        { text: 'ClientID', sortable: true, value: 'clientid' },
        { text: 'Date', sortable: true, value: 'date' },
        { text: 'End Date', sortable: true, value: 'enddate' },
        { text: 'Value', sortable: true, value: 'value' }
      ],
      sshpfHeaders: [
        { text: '', sortable: false, value: '' },
        { text: 'RequestID', sortable: true, value: 'requestid' },
        { text: 'ClientID', sortable: true, value: 'clientid' },
        { text: 'Owner', sortable: true, value: 'owner' },
        { text: 'Submitted', sortable: true, value: 'submitted' },
        { text: 'Expires', sortable: true, value: 'completed' },
        { text: 'Status', sortable: true, value: 'status' },
        { text: 'Port', sortable: false, value: 'combinedoutput' }
      ],
      selectedAlarm: null,
      cnRegex: /^[a-zA-Z0-9-]+-[svc]{1}[0-9]+[n]?[0-9]*.insolar-plants.net$/,
      cnRules: [
        v => v && v.match(this.cnRegex) && true || 'Must match ' + this.cnRegex
      ],
      pageAggregated: 1,
      pageCountAgregated: 0,
      pageCountAlarms: 0,
      showRequest: false,
      request: null,
      startTasks: 0,
      limitTasks: 0,
      showCombinedOutput: false,
      combinedOutput: null,
      pageAlarms: 1,
      startAlarms: 0,
      limitAlarms: 0,
      confirm: false,
      confirmRestart: false,
      confirmUpgrade: false,
      confirmDelete: false,
      confirmCertificate: false,
      confirmDeregister: false,
      daemonname: null,
      daemonstatus: null,
      enablegrpc: true,
      webproxydialog: false,
      webopendialog: false,
      webpage: null,
      sessionkey: false,
      remote: 'https://localhost:443',
      sshpfdialog: false,
      sshpfhostport: "localhost:80",
      sshpftimeout: 7200,
      sshpfallowedips: null,
      sshpfhost: "",
      displayAlarm: false,
      upgradeVersion: null,
      sysbarColor: 'success',
      sysbarMessage: '',
      snack: false,
      snackColor: 'success',
      snackMessage: '',
      snackTimeout: 3000
    }
  },
  components: { AgentHistory, RequestDetails, NewTask },
  watch: {
    myAgent: function(v) {
      if (v) {
        // Cleanup.
        // this.clearMemInstances();
        // this.clearInstances();
        // this.cancelWatchFileTransfers();
        // this.cancelWatchJobs();
        // this.cancelWatchTasks();
        // this.cancelWatchRequests();
        this.sysbarColor = 'success';
        this.sysbarMessage = '';
        // Instances.
        this.filterMemInstances({ agentid: this.agentid }).then(() => {
          var onlineInstances = Object.values(this.allMemInstances).filter((i) => i.status != "Offline");
          if (this.myAgent && this.myAgent.status != "Offline" && onlineInstances.length == 0) {
            this.sysbarColor = 'warning';
            this.sysbarMessage = 'Invalid state detected.';
          }
        });
        // Alarms.
        this.refreshAlarms();
        // Tasks.
        // this.refreshTasks();
        // gRPC.
        this.getGRPC();
      }
    },
    agentid: function(v) {
      if (!this.routechange) {
        this.$router.push({ name: 'agent-details', params: { AgentID: v } });
      }
      this.refreshTasks(true);
      this.routechange = false;
    },
    '$route.params.AgentID': function (v) {
      if (this.agentid != v) {
        this.routechange = true;
        this.agentid = v;
      }
    }
  },
  mounted() {
    // Cleanup.
    this.clearMemInstances();
    this.clearInstances();
    this.cancelWatchFileTransfers();
    this.cancelWatchJobs();
    this.cancelWatchTasks();
    this.cancelWatchRequests();
    // MemInstances.
    this.filterMemInstances({ agentid: this.agentid }).then(() => {
      var onlineInstances = Object.values(this.allMemInstances).filter((i) => i.status != "Offline");
      if (this.myAgent && this.myAgent.status != "Offline" && onlineInstances.length == 0) {
        this.sysbarColor = 'warning';
        this.sysbarMessage = 'Invalid state detected.';
      }
    });
    // Alarms.
    this.refreshAlarms();
    // Tasks.
    this.refreshTasks(true);
    // gRPC.
    this.getGRPC();
    // SSH PF.
    this.getSshPfHost();
  },
  beforeDestroy() {
    this.clearMemInstances();
    this.clearInstances();
    this.cancelWatchFileTransfers();
    this.cancelWatchJobs();
    this.cancelWatchTasks();
    this.cancelWatchRequests();
  },
  computed: {
    loadingAggregated: {
      get() {
        return this.isLoading.requests || this.isLoading.tasks || this.isLoading.jobs || this.isLoading.filetransfers;
      }
    },
    itemsPerPage: {
      get() {
        return this.pagination(this.userConfig.itemsperpage);
      }
    },
    pinned: {
      get() {
        if (!this.userConfig || !this.userConfig.pinnedagentsidsList) return false;
        var pinned = false;
        this.userConfig.pinnedagentsidsList.forEach((v) => { if (v == this.agentid) { pinned = true; } })
        return pinned;
      }
    },
    pinnedColor: {
      get() {
        if (this.pinned) return 'primary';
        else return 'grey';
      }
    },
    pinnedAgents: {
      get() {
        if (!this.pinned || !this.userConfig.pinned || !this.userConfig.pinnedagentsidsList) {
          return this.allAgents;
        }
        var pinnedAgents = {};
        for (const [key, value] of Object.entries(this.allAgents)) {
          this.userConfig.pinnedagentsidsList.forEach((v) => {
            if (key == v) pinnedAgents[key] = value;
          })
          // Always add current agent otherwise title is removed from view.
          if (key == this.agentid) pinnedAgents[key] = value;
        }
        return pinnedAgents;
      }
    },
    allAgentIds: {
      get() {
        var ids = [];
        if (this.pinnedAgents) {
          Object.values(this.pinnedAgents).forEach((v) => {
            ids.push(v.agentid);
          });
        }
        return ids;
      }
    },
    myAgent: {
      get() {
        return this.getAgentById(this.agentid);
      }
    },
    myInstances: {
      get() {
        var lastInstance = [this.myAgent.lastinstance];
        var onlineInstances = Object.values(this.allMemInstances).filter((i) => i.status != "Offline");
        if (this.myAgent && this.myAgent.status == "Offline" || onlineInstances.length == 0) {
          return lastInstance;
        }
        return onlineInstances;
      }
    },
    sshpf: {
      get() {
        var re = new RegExp('Allocated port .* for remote forward to .*');
        var pfs = Object.values(this.aggregatedList).filter((a) => a.command == "SSH PF" && !a.fileobjectid);
        pfs.forEach((v) => {
          var t = this.decodeCombinedOutput(v.combinedoutput).match(re);
          if (t) {
            v.allocatedport = t[0].replace('port ', this.sshpfhost + ':');
          }
          // Task must be DONE from an online agent to show as 'active'.
          // if (v.status == this.jobStatus().DONE && this.myAgent.lastinstance.offlineat == 0 && v.clientid == this.myAgent.lastinstance.clientid) {
          v.active = false;
          if (v.status == this.jobStatus().DONE) {
            this.myAgent.instancekeysMap.forEach((vv) => {
              var vvv = Object.values(this.myInstances).filter((i) => i.clientid == vv[0]);
              if (vvv && vvv.length == 1 && vvv[0].status.includes("Registered")) {
                v.active = true;
              }
            });
          }
          v.expires = v.submitted + v.timeout * 1e9;
        });
        return pfs;
      }
    },
    aggregatedList: {
      get() {
        var s = {};
        // Jobs
        if (this.allJobs) {
          Object.values(this.allJobs).forEach((j) => {
            var o = Object.assign({}, j);
            var r = this.allRequests[this.idFromKey(j.requestkey)];
            if (r) {
              o.owner = r.owner;
              o.command = this.recipeOrCommand(r);
              o.timeout = r.jobtimeout;
            } else {
              // TODO: getRequest
            }
            o.requestid = this.idFromKey(o.requestkey);
            o.skey = this.idFromKey(o.requestkey)+o.jobid;
            s[o.skey] = o;
          });
        }
        // FileTransfers
        if (this.allFileTransfers) {
          Object.values(this.allFileTransfers).forEach((ft) => {
            var o = Object.assign({}, ft);
            var r = this.allRequests[this.idFromKey(ft.requestkey)];
            if (r) {
              o.owner = r.owner;
              o.command = this.recipeOrCommand(r);
            } else {
              // TODO: getRequest
            }
            o.requestid = this.idFromKey(o.requestkey);
            o.skey = this.idFromKey(o.requestkey)+o.filetransferid;
            s[o.skey] = o;
          });
        }
        // Tasks without Jobs and FileTrasfers (yet or not)
        if (this.allTasks) {
          Object.values(this.allTasks).forEach((t) => {
            if (t.jobs == 0 && t.filetransfers == 0) {
              var o = Object.assign({}, t);
              var r = this.allRequests[this.idFromKey(t.requestkey)];
              if (r) {
                o.owner = r.owner;
                o.command = this.recipeOrCommand(r);
              } else {
              // TODO: getRequest
              }
              o.requestid = this.idFromKey(o.requestkey);
              o.skey = this.idFromKey(o.requestkey)+o.taskid;
              s[o.skey] = o;
            }
          });
        }
        return Object.values(s);
      }
    },
    myAlarms: {
      get() {
        return Object.values(this.allAlarms).filter((a) => a.agentid == this.agentid);
      }
    },
    myWatchers: {
      get() {
        var v = Object.values(this.allWatchers).filter((a) => a.agentid == this.agentid);
        if (v.length == 0) {
          return {};
        } else {
          return v[0].watchersMap;
        }
      }
    },
    ...mapGetters(['allAgents', 'getAgentById', 'allMemInstances',
                    'allTasks', 'allRequests', 'allJobs', 'allFileTransfers',
                    'allRecipes', 'getFileById', 'allAlarms', 'allWatchers',
                    'userConfig', 'getUserById', 'isLoading'])
  },
  methods: {
    getGRPC() {
      if (this.$cookie.get("disablegrpc") == "true") {
        this.enablegrpc = false;
      } else {
        this.enablegrpc = true;
      }
    },
    setGRPC() {
      this.$cookie.set("disablegrpc", !this.enablegrpc);
    },
    openShell(clientid) {
      auth.getAccessToken().then(accessToken => {
        const BASE_URI = process.env.NODE_ENV == 'production' ? window.location.protocol+'//'+window.location.hostname+'/_console/?' : 'http://localhost:3000/console/?';
        const encryptedAES = this.CryptoJS.AES.encrypt(accessToken, config.challenge).toString();
        this.$cookie.set("token", encryptedAES, { expires: '3s' });
        this.sleep(100).then(() => {
          var w = window.open(BASE_URI+this.agentid+'/'+clientid, "_blank", "width=850,height=580");
          w.focus();
        });
      });
    },
    callOpenWebLocal(clientid) {
      this.clientid = clientid;
      this.remote = 'https://localhost:443';
      this.callOpenWeb();
    },
    callOpenWebRemote(clientid) {
      this.clientid = clientid;
      this.webproxydialog = true;
    },
    callOpenWeb() {
      this.webpage = null;
      this.remote = this.remote.split("/").splice(0, 3).join("/");
      this.openWeb({agentid: this.agentid, clientid: this.clientid, remote: this.remote, disablegrpc: !this.enablegrpc}).then((v) => {
        this.webpage = 'https://'+v.webid+'.'+window.location.hostname.replace('coolagent','coolweb')+'/_';
        this.sessionkey = v.sessionkey;
        this.webopendialog = true;
      }).catch(() => {
        if (this.enablegrpc) {
          this.sysbarColor = 'warning';
          this.sysbarMessage = 'gRPC failed and was disabled for this agent';
          this.enablegrpc = false;
          this.openWeb({agentid: this.agentid, clientid: this.clientid, remote: this.remote, disablegrpc: !this.enablegrpc}).then((v) => {
            this.webpage = 'https://'+v.webid+'.'+window.location.hostname.replace('coolagent','coolweb')+'/_';
            this.sessionkey = v.sessionkey;
            this.webopendialog = true;
          }).catch(() => { this.enablegrpc = true; });
        }
      });
    },
    callOpenSshPfDialog(clientid) {
      this.clientid = clientid;
      this.sshpfdialog = true;
    },
    callOpenSshPf() {
      this.callNewRequest({
        agentid: this.agentid,
        clientid: this.clientid,
        op: "SSH PF",
        oparguments: this.sshpfhostport,
        jobtimeout: this.sshpftimeout,
        remoteaddress: this.sshpfallowedips
      });
    },
    callDeregisterInstance() {
      this.deregisterInstance({agentid: this.agentid, clientid: this.clientid}).then((v) => {
        this.snack = true;
        this.snackColor = 'success';
        this.snackMessage = v.agentid + '/' + v.clientid + ' deregisterd';
      }).catch(() => {});
    },
    callDeleteAgent() {
      this.deleteAgent({agentid: this.agentid}).then((v) => {
        this.snack = true;
        this.snackColor = 'success';
        this.snackMessage = v.agentid + ' deleted';
        this.sleep(1000).then(() => { 
          this.openAgentsPage();
        });
      }).catch(() => {});
    },
    getEverything(more) {
      return new Promise((resolve, reject) => {
        this.getRequests(more).then(() => {
          this.getTasks(more).then(() => {
            this.getJobs(more).then(() => {
              this.getFileTransfers(more).then(() => {
                resolve();
              }).catch(() => { reject(); });
            }).catch(() => { reject(); });
          }).catch(() => { reject(); });
        }).catch(() => { reject(); });
      });
    },
    getEverythingMore(warp) {
      if (!warp) warp = 1;
      var n = Object.keys(this.allRequests).length;
      this.limitTasks = this.startTasks;
      this.startTasks = this.startTasks - config.historyMs * 1e6 * warp;
      this.getEverything(true).then(() => {
        if (Object.keys(this.allRequests).length === n) {
          if (this.startTasks < config.beginningMs * 1e6) return; // before 20200101
          this.getEverythingMore(warp + 1); // incremental
        }
      }).catch(() => {});
    },
    getRequests(more) {
      var args = {agentid: this.agentid, start: this.startTasks, limit: this.limitTasks, more: more};
      return this.filterRequests(args);
    },
    getTasks(more) {
      var args = {agentid: this.agentid, start: this.startTasks, limit: this.limitTasks, more: more};
      return this.filterTasks(args);
    },
    getJobs(more) {
      var args = {agentid: this.agentid, start: this.startTasks, limit: this.limitTasks, more: more};
      return this.filterJobs(args);
    },
    getFileTransfers(more) {
      var args = {agentid: this.agentid, start: this.startTasks, limit: this.limitTasks, more: more};
      return this.filterFileTransfers(args);
    },
    displayCombinedOutput(v) {
      this.combinedOutput = this.decodeCombinedOutput(v.combinedoutput);
      if (v.hostid) {
        this.hostid = v.hostid + " (Job)";
      } else {
        this.hostid = v.clientid + " (Job)";
      }
      this.showCombinedOutput = true
    },
    displayErrorOutput(v) {
      this.combinedOutput = v.error;
      if (v.hostid) {
        this.hostid = v.hostid + " (File Transfer)";
      } else {
        this.hostid = v.clientid + " (File Transfer)";
      }
      this.showCombinedOutput = true;
    },
    displayFileTransfer(v) {
      this.combinedOutput = v.filename + " (" + v.fileperms.user + ":" + v.fileperms.group + " " + v.fileperms.mode + ")";
      if (v.hostid) {
        this.hostid = v.hostid + " (File Transfer)";
      } else {
        this.hostid = v.clientid + " (File Transfer)";
      }
      this.showCombinedOutput = true;
    },
    selectRequest(requestid) {
      this.request = this.allRequests[requestid];
      this.showRequest = true;
      // this.$router.push({ path: `/requests/${requestid}`});
    },
    openAgentsPage() {
      this.$router.push({ name: 'agents-page', params: { filter: this.filter } });
    },
    owner(requestid) {
      return this.allRequests[requestid].owner;
    },
    recipeOrCommand(request) {
      if (this.allRecipes[request.recipeid]) {
        return this.allRecipes[request.recipeid].name;
      }
      if (request.filesList && request.filesList.length > 0) {
        return request.filesList[0].filename;
      }
      return request.command;
    },
    getSshPfHost() {
      var recipe = Object.values(this.allRecipes).filter((r) => r.name == 'SSH PF');
      var re = new RegExp('@.*.com');
      if (recipe.length == 1) {
        this.sshpfhost = recipe[0].data.match(re)[0].replace('@', '');
      }
    },
    toggleDaemon(daemon, status) {
      this.daemonname = daemon;
      this.daemonstatus = status;
      this.confirm = true;
    },
    applyToggleDeamonStatus() {
      if (this.daemonstatus == this.daemonStatus().ON) {
        this.callNewRequest({agentid: this.agentid, op: "Service Stop", oparguments: this.daemonname});
      } else {
        this.callNewRequest({agentid: this.agentid, op: "Service Start", oparguments: this.daemonname});
      }
    },
    coolagentUpgrade() {
      this.callNewRequest({agentid: this.agentid, op: "CoolAgent Upgrade ("+this.myAgent.lastinstance.info.arch+")"});
    },
    coolagentRestart() {
      this.callNewRequest({agentid: this.agentid, op: "CoolAgent Restart"});
    },
    createCertificate() {
      if (!this.cn || !this.cn.match(this.cnRegex)) {
        this.snack = true;
        this.snackColor = 'error'
        this.snackMessage = 'Common Name ' + this.cn + ' is invalid. Must match ' + this.cnRegex;
        return false;
      }
      if (this.cn == this.agentid) {
        this.snack = true;
        this.snackColor = 'error'
        this.snackMessage = 'This Agent already has a valid certificate';
        return false;       
      }
      this.snack = true;
      this.snackColor = 'info';
      this.snackMessage = "You will find this Agent again as " + this.cn;
      this.callNewRequest({agentid: this.agentid, clientid: this.clientid, op: "Create Certificate", oparguments: this.cn});
    },
    callNewRequest(v) {
      var args = {
        clientid: v.clientid,
        oparguments: v.oparguments,
        jobtimeout: v.jobtimeout,
        remoteaddress: v.remoteaddress,
        concurrent: v.concurrent,
        recipeid: v.recipeid,
        owner: this.$auth.profile.email,
        filterList: [
          {
            field: 'Agent ID',
            value: [v.agentid],
            operator: 'is',
            multioperator: 'AND',
          }
        ]
      };
      for (const k in this.allRecipes) {
        if (this.allRecipes[k].name == v.op) {
          args.recipeid = this.allRecipes[k].recipeid;
          break;
        }
      }
      this.newRequest(args).then((v) => {
        this.snack = true;
        this.snackColor = 'success'
        this.snackMessage = 'Request ID ' + v.requestid;
      }).catch(() => {});
    },
    upgradeAvailable() {
      if (!this.myAgent || !this.allRecipes) return false;
      if (this.myAgent.status == "Offline") return false;
      var rname = "CoolAgent Upgrade ("+this.myAgent.lastinstance.info.arch+")";
      var foid = "";
      for (const k in this.allRecipes) {
        if (this.allRecipes[k].name == rname && this.allRecipes[k].filesList.length > 0) {
          foid = this.allRecipes[k].filesList[0].fileobjectid;
          break;
        }
      }

      var fo = this.getFileById(foid)
      if (!fo) return false;

      var a = this.myAgent.version.split('.');
      var b = fo.version.split('.');
      var i = 0;

      for (i = 0; i < a.length; ++i) {
          a[i] = Number(a[i]);
      }
      for (i = 0; i < b.length; ++i) {
          b[i] = Number(b[i]);
      }
      if (a.length == 2) {
          a[2] = 0;
      }
      if (a[0] > b[0]) return false;
      if (a[0] < b[0]) { this.upgradeVersion = fo.version;  return true; }

      if (a[1] > b[1]) return false;
      if (a[1] < b[1]) { this.upgradeVersion = fo.version;  return true; }

      if (a[2] > b[2]) return false;
      if (a[2] < b[2]) { this.upgradeVersion = fo.version;  return true; }

      return false;
    },
    refreshTasks(routechange){
      if (routechange) {
        this.cancelWatchFileTransfers();
        this.cancelWatchJobs();
        this.cancelWatchTasks();
        this.cancelWatchRequests();
      }
      this.startTasks = (new Date().getTime()-config.historyMs) * 1e6;
      this.limitTasks = new Date().getTime() * 1e6;
      this.filterUsers().then(() => {
        if (routechange) {
          this.watchRequests({ agentid: this.agentid });
          this.watchTasks({ agentid: this.agentid });
          this.watchJobs({ agentid: this.agentid });
          this.watchFileTransfers({ agentid: this.agentid });
        }
        return this.getEverything().then(() => {}).catch(() => {});
      });
    },
    refreshAlarms() {
      this.startAlarms = (new Date().getTime()-config.historyMs) * 1e6;
      this.limitAlarms = new Date().getTime() * 1e6;
      return this.getAlarms().then(() => {}).catch(() => {});
    },
    getAlarms(more) {
      var args = {agentid: this.agentid, name: this.name, start: this.startAlarms, limit: this.limitAlarms, more: more};
      return this.filterAlarms(args);
    },
    getAlarmsMore(warp) {
      if (!warp) warp = 1;
      var n = Object.keys(this.myAlarms).length;
      this.limitAlarms = this.startAlarms;
      this.startAlarms = this.startAlarms - 24 * 3600 * 1e9 * warp;
      this.getAlarms(true).then(() => {
        if (Object.keys(this.myAlarms).length === n) {
          if (this.startAlarms < config.beginningMs * 1e6) return; // before 20200101
          this.getAlarmsMore(warp + 1); // incremental
        }
      }).catch(() => {});
    },
    showAlarm(v) {
      if (v) {
        this.selectedAlarm = v;
        this.displayAlarm = true;
      }
    },
    pinOrUnpin() {
      var config = Object.assign({}, this.userConfig);
      if (!this.pinned) {
        config.pinnedagentsidsList.push(this.agentid);
      } else {
        const index = config.pinnedagentsidsList.indexOf(this.agentid);
        if (index > -1) {
          config.pinnedagentsidsList.splice(index, 1);
        }
      }
      this.setUserConfig(config).then(() => {}).catch(() => {});
    },
    hasNewTaskFullAccess() {
      if (this.$auth.profile) {
        var u = this.getUserById(this.$auth.profile.sub);
        if (u) return u.attributes.newtaskfullaccess;
      }
      return false;
    },
    hasAaaAccess() {
      if (this.$auth.profile) {
        var u = this.getUserById(this.$auth.profile.sub);
        if (u) return u.attributes.aaaaccess;
      }
      return false;
    },
    updatePagination (pagination) {
      if (!this.userConfig || !this.userConfig.sub) return;
      if (this.userConfig.itemsperpage == pagination.itemsPerPage) return
      var config = Object.assign({}, this.userConfig);
      config.itemsperpage = pagination.itemsPerPage;
      this.setUserConfig(config).then(() => {}).catch(() => {});
    },
    exportAgent() {
      var data = Object.assign({}, this.myAgent);
      data["watchersMap"] = this.myWatchers;
      var dataStr = JSON.stringify(data, null, 2);
      var dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      var exportFileDefaultName = this.agentid+'.json';
      var linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    },
    exportTasks() {
      var myRequests = [];
      Object.values(this.allTasks).forEach((t) => {
        var request = Object.assign({}, this.allRequests[t.requestid]);
        request.status = this.taskStatusToString(request.status);
        myRequests.push(request);
      });
      var allData = [];
      var parent = this;
      Object.values(this.allRequests).forEach((r) => {
        var data = Object.assign({}, r);
        data["tasks"] = (function() {
          var tasks = [];
          var grandpa = parent;
          Object.values(parent.allTasks).forEach((t) => {
            if (parent.idFromKey(t.requestkey) == r.requestid) {
              var task = Object.assign({}, t);
              task.status = parent.taskStatusToString(task.status);
              task["jobs"] = (function() {
                var jobs = [];
                Object.values(grandpa.allJobs).forEach((j) => {
                  if (grandpa.idFromKey(j.taskkey) == t.taskid) {
                    var job = Object.assign({}, j);
                    job.status = grandpa.jobStatusToString(job.status);
                    jobs.push(job);
                  }
                });
                return jobs;
              })();
              tasks.push(task);
            }
          });
          return tasks;
        })();
        allData.push(data);
      });
      var dataStr = JSON.stringify(allData, null, 2);
      var dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      var exportFileDefaultName = 'tasks'+this.agentid+'.json';
      var linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    },
    exportTasksCSV() {
      var parent = this;
      var dataStr = "RequestID,AgentID,Owner,Submitted,Completed,Recipe,Command,Status,ExitStatus,Output\n";
      this.aggregatedList.forEach((a) => {
        var r = parent.allRequests[a.requestid];
        var data = a.requestid + "," + a.agentid + "," + a.owner + ","
                  + parent.dateToStringEmpty(a.submitted) + "," + parent.dateToStringEmpty(a.completed) + ","
                  + r.recipeid + "," + JSON.stringify(r.command).replace(",", "&#44;") + ","
                  + parent.jobStatusToString(a.status) + "," + a.exitstatus + "," + JSON.stringify(this.decodeCombinedOutput(a.combinedoutput)).replace(",", "&#44;") + "\n";
        dataStr += data;
      });
      var dataUri = 'data:application/csv;charset=utf-8,'+ encodeURIComponent(dataStr);
      var exportFileDefaultName = 'tasks-'+this.agentid+'.csv';
      var linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    },
    exportAlarms() {
      var dataStr = JSON.stringify(Object.values(this.myAlarms), null, 2);
      var dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      var exportFileDefaultName = 'alarms-'+this.agentid+'.json';
      var linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    },
    ...mapActions(['filterMemInstances', 'clearMemInstances', 'clearInstances', 'deregisterInstance',
                    'deleteAgent',
                    'openWeb',
                    'filterAlarms', 'filterUsers',
                    'newRequest', 'filterRequests', 'filterTasks', 'filterJobs', 'filterFileTransfers',
                    'watchRequests', 'watchTasks', 'watchJobs', 'watchFileTransfers',
                    'cancelWatchRequests', 'cancelWatchTasks', 'cancelWatchJobs', 'cancelWatchFileTransfers',
                    'setUserConfig',])
  }
}
</script>
